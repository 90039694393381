<template>
  <div class="refractiveAssessment">
    <h3>裸眼状态</h3>
    <div class="srA">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>裸眼视力</li>
          <li>DS(追加)</li>
          <li>DC</li>
          <li>AX</li>
          <li>VA</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('CL_Recheck_R_nakedOD')" v-model="postData.CL_Recheck_R_nakedOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_DSOD', 1)" v-model="postData.CL_Recheck_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_DCOD', 1)" v-model="postData.CL_Recheck_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_XOD')" v-model="postData.CL_Recheck_R_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_VAOD')" v-model="postData.CL_Recheck_R_VAOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('CL_Recheck_R_nakedOS')" v-model="postData.CL_Recheck_R_nakedOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_DSOS', 1)" v-model="postData.CL_Recheck_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_DCOS', 1)" v-model="postData.CL_Recheck_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_XOS')" v-model="postData.CL_Recheck_R_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_R_VAOS')" v-model="postData.CL_Recheck_R_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <h3>戴镜状态</h3>
    <div class="srA">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>裸眼视力</li>
          <li>DS(追加)</li>
          <li>DC</li>
          <li>AX</li>
          <li>VA</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('XL_Recheck_R_correctionOD')" v-model="postData.XL_Recheck_R_correctionOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_DSOD',1)" v-model="postData.XL_Recheck_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_DCOD',1)" v-model="postData.XL_Recheck_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_XOD')" v-model="postData.XL_Recheck_R_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_VAOD')" v-model="postData.XL_Recheck_R_VAOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('XL_Recheck_R_correctionOS')" v-model="postData.XL_Recheck_R_correctionOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_DSOS',1)" v-model="postData.XL_Recheck_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_DCOS',1)" v-model="postData.XL_Recheck_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_XOS')" v-model="postData.XL_Recheck_R_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('XL_Recheck_R_VAOS')" v-model="postData.XL_Recheck_R_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "refractiveAssessment",
  data() {
    return {
      postData: {
        CL_Recheck_R_nakedOD: "",
        CL_Recheck_R_DSOD: "",
        CL_Recheck_R_DCOD: "",
        CL_Recheck_R_XOD: "",
        CL_Recheck_R_VAOD: "",
        CL_Recheck_R_nakedOS: "",
        CL_Recheck_R_DSOS: "",
        CL_Recheck_R_DCOS: "",
        CL_Recheck_R_XOS: "",
        CL_Recheck_R_VAOS: "",
        XL_Recheck_R_correctionOD: "",
        XL_Recheck_R_DSOD: "",
        XL_Recheck_R_DCOD: "",
        XL_Recheck_R_XOD: "",
        XL_Recheck_R_VAOD: "",
        XL_Recheck_R_correctionOS: "",
        XL_Recheck_R_DSOS: "",
        XL_Recheck_R_DCOS: "",
        XL_Recheck_R_XOS: "",
        XL_Recheck_R_VAOS: ""
      }
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('corneaContact/upCorneaReview', n)
      },
      deep: true
    },
    "$store.state.corneaContact.isfcshowdata": {
      deep: true,
      handler(n, o) {
        if (!n) {return false}
        for (let key in this.postData) {
          this.postData[key] = this.$store.state.corneaContact.corneaReview[key]
        }
      }
    }
  },
  created() {
    // this.postData = this.$store.state.corneaContact.corneaReview
  },

  methods: {
    zh(key, type=0) {
      let str = this.postData[key]
      if (str !== '' && Number(str) > 0) {
        if (type && this.postData[key][0] !== '+') {
          this.postData[key] = "+" + str
        }else {
          this.postData[key] = str.toString()
        }
      }else {
        this.postData[key] = str !== ''?str.toString():''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  h3 {
      padding: .3rem 0;
      text-align: left;
  }
  .mar-20 {margin-top: 30px}
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      .ys {color: #40a7f6;}
      li {
        width: 8vw;
        margin-right: .4rem;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: .5rem;
      margin-top: 32px;
    }
  }
</style>
